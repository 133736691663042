/*eslint-disable*/
import React, { useState } from "react";
import Layout from "../../components/chat-channel/Layout";
import Container from "../../components/chat-channel/Container";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import { RequestForm } from "../../components/form";
import { useMedia } from "use-media";
import { OnScrollPopup } from "../conversational-ai-platform/hr-chatbot";

import {
  VideoHowtoIcons,
  VideoUsecasesIcon,
  VideoTrainingIcon,
  VideoProductIcon,
  VideoAllIcon,
  VideoAppsIcon,
  PlayIcons,
} from "../../react/Icons";

import { categories, videos } from "../../../video-category-data";
import { GoToPopup } from "../homepage";
import { CookiesPoup } from "../homepage";

const HeaderLeftImg = require("../../assets/images/videos_header.png");

export const Image = require("../../assets/images/video_icons/dummy_image.png");

export default function MarketPlace(props) {
  const isSmall = useMedia({ maxWidth: "520px" });
  return (
    <>
      <TitleAndMetaTags
        title="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        description="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
        keywords={[
          "IT Helpdesk Chatbot",
          "HR Chatbot",
          "Workativ Integrations",
        ]}
        ogTitle="Free Chatbot & Automation Videos | Watch How to Videos of Workativ Assistant - HR & IT Helpdesk Chatbot"
        ogDescription="Conversational AI and Process Automation Videos. Learn how to build chatbot with process automation."
        ogImage={HeaderLeftImg}
      />
      <Container>
        <div className="w-100 float-left videos_wrapper">
          <Layout backgroundColor={"bg_videos"} logoFor="ASSISTANT">
            <section className="assistant_slider trial_mb_80 bg_market_webinar bg_video">
              <div className="container">
                <div className="row">
                  <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
                    <div className="main-slider-left">
                      <h1>
                        Know more about the innovative capabilities of Workativ
                        Assistant and transform your workplace support
                      </h1>
                      <p>Subscribe now for new videos</p>
                      <div className="footer_form">
                        <RequestForm isFooterForm={false} fromPage="assistant-videos" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 bg_video_img">
                    <div className="main-slider-right assistant_right_img beta-slider">
                      <img src={HeaderLeftImg} alt={HeaderLeftImg} />
                    </div>
                  </div>
                </div>
              </div>
            </section>{" "}
            <VideosWrapper />
            <RequestForm isFooterForm={true} fromPage="assistant-videos" />
            {/* {isSmall ? null : <OnScrollPopup />} */}
            {/* <GoToPopup />
            <CookiesPoup /> */}
          </Layout>
        </div>
      </Container>
    </>
  );
}

const iconMap = [
  {
    icon: <VideoAllIcon />,
    key: "1",
  },
  {
    icon: <VideoHowtoIcons />,
    key: "2",
  },
  {
    icon: <VideoUsecasesIcon />,
    key: "3",
  },
  {
    icon: <VideoAppsIcon />,
    key: "4",
  },
  {
    icon: <VideoAppsIcon />,
    key: "5",
  },
  {
    icon: <VideoAppsIcon />,
    key: "6",
  },
];

// redis-cli -h 127.0.0.1 -p 6379 -a 'password123'

// console.log('categories', categories);
export const categoryWithVideoData = categories
  // .filter((category) => category.type.hirarchy === "main")
  .map((category) => {
    const icon = iconMap.find((icon) => icon.key === category.key);

    const count = videos.filter((video) =>
      video.categories.includes(category.key)
    ).length;

    return {
      ...category,
      // icon: icon && icon.icon ? icon.icon : <VideoHowtoIcons />,
      // count: `(${count})`,
    };
  });

export const categoryMainMenu = categoryWithVideoData.filter(
  (category) => category.type.hirarchy === "main"
);

// console.log("iconMap", iconMap, MenuLists);
// export const MenuLists = [
//   {
//     icon: <VideoAllIcon />,
//     menu: "All Videos",
//     count: "(103)",
//     key: "1",
//     showSubmenu: "open",
//   },
//   {
//     icon: <VideoHowtoIcons />,
//     menu: "How to",
//     count: " (36) ",
//     key: "2",
//     showSubmenu: "open",
//   },

//   {
//     icon: <VideoUsecasesIcon />,
//     menu: "Usecases ",
//     count: "(23)",
//     key: "3",
//     showSubmenu: "open",
//   },
//   {
//     icon: <VideoAppsIcon />,
//     menu: "Applications",
//     count: "(23)",
//     key: "4",
//     showSubmenu: "open",
//     subMenu: [
//       {
//         menu: "Freshdesk",
//         count: "1",
//         key: "4.1",
//       },
//       {
//         menu: "Freshservice",
//         count: "43",
//         key: "4.1",
//       },
//       {
//         menu: "Zendesk",
//         count: "4",
//         key: "4.1",
//       },
//     ],
//   },
//   // {
//   //   icon: <VideoTrainingIcon />,
//   //   menu: "Training ",
//   //   count: "(19)",
//   //   key: "Training",
//   //   showSubmenu: "open"
//   // },
//   // {
//   //   icon: <VideoProductIcon />,
//   //   menu: "Product Videos ",
//   //   count: "(15)",
//   //   key: "Product Videos",
//   //   showSubmenu: "open",
//   // },
// ];

const VideosWrapper = () => {
  const parsedUrl = new URL(window.location.href);

  const categoryFromUrl = parsedUrl.searchParams.get("cat");

  const isSmall = useMedia({ maxWidth: "992px" });

  return (
    <div className="videos_container">
      <div className="container">
        {isSmall ? (
          <MobileDropdown category={categoryFromUrl} />
        ) : (
          <VideosList category={categoryFromUrl} />
        )}
      </div>
    </div>
  );
};

const VideosList = (props) => {
  const [activeCategory, setActiveCategory] = useState(
    props.category ? props.category : "1"
  );

  const activeCategoryItem = categoryWithVideoData.find(
    (cat) => cat.key === activeCategory
  );

  const activeMainMenu =
    activeCategoryItem.type.hirarchy === "main"
      ? activeCategory
      : categoryWithVideoData.find(
          (cat) => cat.key === activeCategoryItem.type.for
        ).key;

  const activeSubMenu =
    activeCategoryItem.type.hirarchy === "sub" ? activeCategory : null;

  const subMenuList = categoryWithVideoData.filter(
    (category) =>
      category.type.hirarchy === "sub" && category.type.for == activeMainMenu
  );
  const [openSubMenu, setOpenSubmenu] = useState(
    subMenuList.length > 0 ? true : false
  );
  // console.log(
  //   activeMainMenu,
  //   activeSubMenu,
  //   subMenuList,
  //   openSubMenu,
  //   categoryWithVideoData
  // );
  const filteredVideos = videos.filter((video) =>
    video.categories.includes(activeCategory)
  );

  const handleOnClick = (active) => {
    if (active === activeCategory) {
      setOpenSubmenu(false);
    } else {
      setActiveCategory(active);
      setOpenSubmenu(true);
    }
  };

  // const handleOnClickMobile = (subMenu) => {
  //   setOpenSubmenu(subMenu);
  // };
  return (
    <React.Fragment>
      <section className="video_lists col-12 col-lg-3 ">
        <div className="video_lists_lg">
          <div className="video_lists_container">
            <ul className="video_list_ul" active={activeCategory}>
              <div className="video_list_ul_header">Category</div>
              <div className="p-2 video_list_li  border-0"></div>
              {categoryMainMenu.map((category, index) => (
                <li
                  key={index}
                  onClick={() => {
                    handleOnClick(category.key);
                  }}
                >
                  <div
                    className={
                      activeMainMenu === category.key
                        ? "video_list_li video_list_li_active"
                        : "video_list_li"
                    }
                  >
                    <div className="video_svg_iocn">{category.icon}</div>
                    <div className="video_content_li">
                      {category.menu} <span>{category.count}</span>
                    </div>
                  </div>
                  {openSubMenu && activeMainMenu === category.key && (
                    <div className="submenu_active">
                      <ul>
                        {subMenuList.map((data) => (
                          <li
                            key={data.key}
                            onClick={() => {
                              console.log("sub menu click");
                              handleOnClick(data.key);
                              event.stopPropagation();
                              event.preventDefault();
                            }}
                            className={
                              activeSubMenu === data.key
                                ? "submenu_active_li active"
                                : "submenu_active_li"
                            }
                          >
                            {data.menu} <span>{data.count}</span>
                          </li>
                        ))}
                        <li className="last_menu_li">
                          <a href="/">More Applications</a>
                        </li>
                      </ul>
                    </div>
                  )}
                </li>
              ))}
              <div className="p-2 video_list_li  border-0"></div>
            </ul>
          </div>
        </div>
      </section>
      <YoutubeVideos videos={filteredVideos} />
    </React.Fragment>
  );
};

const YoutubeVideos = ({ videos }) => {
  const [searchText, setSearch] = useState("");
  const [shoMore, setshoMore] = useState("hide");

  return (
    <div className="videos_container">
      <section className="utube_videos col-12 col-lg-9 ">
        <div className="search_marketpalce">
          <input
            className="search_click"
            type="search"
            placeholder="Search"
            value={searchText}
            onChange={(e) => setSearch(e.target.value)}
          />
          <span className="search_icon">
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fal"
              data-icon="search"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="svg-inline--fa fa-search fa-w-16 fa-2x"
            >
              <path
                fill="currentColor"
                d="M508.5 481.6l-129-129c-2.3-2.3-5.3-3.5-8.5-3.5h-10.3C395 312 416 262.5 416 208 416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c54.5 0 104-21 141.1-55.2V371c0 3.2 1.3 6.2 3.5 8.5l129 129c4.7 4.7 12.3 4.7 17 0l9.9-9.9c4.7-4.7 4.7-12.3 0-17zM208 384c-97.3 0-176-78.7-176-176S110.7 32 208 32s176 78.7 176 176-78.7 176-176 176z"
              ></path>
            </svg>
          </span>
        </div>
        <div className="utube_rows">
          {videos
            .filter(
              (video) =>
                searchText == "" ||
                video.header.toLowerCase().includes(searchText.toLowerCase())
            )
            .filter((video, index) => shoMore == "show" || index < 9)
            .map((data) => (
              <div className="utube_col_4">
                <a href={data.path.trim()}>
                  <div className="utube_iframe">
                    <div className="position-relative w-100 h-100">
                      <span>
                        <PlayIcons />
                      </span>
                      <div className="pos_rel">
                        <img
                          src={`https://img.youtube.com/vi/${data.videoTag}/mqdefault.jpg`}
                        />
                      </div>
                    </div>
                    <p className="utube_content">{data.header}</p>
                  </div>
                </a>
              </div>
            ))}
        </div>
        {videos.length > 9 && shoMore == "hide" ? (
          <div className="show_more_videos">
            <button
              onClick={() => {
                setshoMore("show");
              }}
            >
              Show More Videos
            </button>
          </div>
        ) : null}
      </section>
    </div>
  );
};

//Dropdown for Mobile
export const MobileDropdown = (props) => {
  const [activeCategory, setActiveCategory] = useState(
    props.category ? props.category : "1"
  );
  const [openSubMenu, setOpenSubmenu] = useState(false);
  const [active, setActive] = useState(false);

  const activeCategoryItem = categoryWithVideoData.find(
    (cat) => cat.key === activeCategory
  );

  const activeMainMenu =
    activeCategoryItem.type.hirarchy === "main"
      ? activeCategory
      : categoryWithVideoData.find(
          (cat) => cat.key === activeCategoryItem.type.for
        ).key;

  const activeSubMenu =
    activeCategoryItem.type.hirarchy === "sub" ? activeCategory : null;

  const subMenuList = categoryWithVideoData.filter(
    (category) =>
      category.type.hirarchy === "sub" && category.type.for == activeMainMenu
  );

  const filteredVideos = videos.filter((video) =>
    video.categories.includes(activeCategory)
  );

  const handleOnClick = (active) => {
    if (active === activeCategory) {
      setOpenSubmenu(false);
    } else {
      setActiveCategory(active);
      setOpenSubmenu(true);
    }
  };

  return (
    <React.Fragment>
      <section className="video_lists col-12 col-lg-3 ">
        <div className="video_lists_lg">
          <div className="video_lists_container">
            <ul
              className="video_list_ul video_list_ul_mobile"
              active={activeCategory}
            >
              <div
                className={active ? "wrapper_left open" : "wrapper_left close"}
                onClick={() => setActive(!active)}
              >
                <h4>{activeCategoryItem.menu}</h4>
                <span className="wrapper_left_mobile">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 5.75">
                    <title></title>
                    <g id="Layer_2" data-name="Layer 2">
                      <g id="Layer_1-2" data-name="Layer 1">
                        <path d="M5,5.75H5a.78.78,0,0,1-.53-.21L.22,1.29a.74.74,0,0,1,0-1,.75.75,0,0,1,1,0L5,4,8.74.22a.74.74,0,0,1,1,1L5.51,5.54A.78.78,0,0,1,5,5.75Z"></path>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>

              {active ? (
                <>
                  {categoryMainMenu.map((category, index) => (
                    <li
                      key={index}
                      onClick={() => {
                        handleOnClick(category.key);
                        const subMenuList = categoryWithVideoData.filter(
                          (_category) => {
                            console.log();
                            return (
                              _category.type.hirarchy === "sub" &&
                              _category.type.for == category.key
                            );
                          }
                        );
                        if (subMenuList.length == 0) setActive(!active);
                      }}
                    >
                      <div
                        className={
                          activeMainMenu === category.key
                            ? "video_list_li video_list_li_active"
                            : "video_list_li"
                        }
                      >
                        <div className="video_svg_iocn">{category.icon}</div>
                        <div className="video_content_li">
                          {category.menu} <span>{category.count}</span>
                        </div>
                      </div>
                      {openSubMenu && activeMainMenu === category.key && (
                        <div className="submenu_active">
                          <ul>
                            {subMenuList.map((data) => (
                              <li
                                key={data.key}
                                onClick={(event) => {
                                  handleOnClick(data.key);
                                  event.stopPropagation();
                                  event.preventDefault();
                                  setActive(!active);
                                }}
                                className={
                                  activeSubMenu === data.key
                                    ? "submenu_active_li active"
                                    : "submenu_active_li"
                                }
                              >
                                {data.menu} <span>{data.count}</span>
                              </li>
                            ))}
                            <li className="last_menu_li">
                              <a href="/">More Applications</a>
                            </li>
                          </ul>
                        </div>
                      )}
                    </li>
                  ))}
                </>
              ) : null}
            </ul>
          </div>
        </div>
      </section>
      <YoutubeVideos videos={filteredVideos} />
    </React.Fragment>
  );
};
